body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.gradient {
  background-image: linear-gradient(to right, #1d3557, #264669, #30577a, #3a698c, #457b9d);
}

/*
https://coolors.co/e63946-f1faee-a8dadc-457b9d-1d3557
*/
.primary-color {
  color: #1d3557;
}
.secondary-color {
  color: #457b9d;
}
.danger-color {
  color: #e63946;
}
